class TokenService {
  getLocalRefreshToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.refreshToken;
  }
  getLocalAccessToken() {
    const user = JSON.parse(localStorage.getItem("user"));
    return user?.token;
  }
  updateLocalToken(token, refreshToken, infor) {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      user.token = token;
      user.refreshToken = refreshToken;
      user.userInfo = infor;
      localStorage.setItem("user", JSON.stringify(user));
    }
  }
  getUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
  setUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
  }
  removeUser() {
    localStorage.removeItem("user");
  }
}

export default new TokenService();
