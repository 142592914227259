import store from "@/state/store";

export default [
  {
    path: "/",
    meta: {
      authRequired: true,
    },
    name: "home",
    component: () => import("./views/tracking-ord/tracking-ord"),
  },
  {
    path: "/pages/404",
    name: "Page-404",
    component: () => import("./views/pages/error-404"),
  },
  {
    path: "/pages/500",
    name: "Page-500",
    component: () => import("./views/pages/error-500"),
  },
  {
    path: "*",
    component: () => import("./views/pages/error-404"),
  },
];
