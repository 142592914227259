import Vue from "vue";
import App from "./App.vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import Vuelidate from "vuelidate";
import VueMask from "v-mask";
import * as VueGoogleMaps from "vue2-google-maps";
import VueSweetalert2 from "vue-sweetalert2";
import VueApexCharts from "vue-apexcharts";
import router from "./router/index";
import store from "@/state/store";
import vco from "v-click-outside";

import "@/design/index.scss";

import Sparkline from "vue-sparklines";
// for notifying
import Snotify, { SnotifyPosition } from "vue-snotify";
// You also need to import the styles. If you're using webpack's css-loader, you can do so here:
import "vue-snotify/styles/material.css"; // or dark.css or simple.css
const options = {
  toast: {
    position: SnotifyPosition.rightTop,
  },
};
Vue.use(Snotify, options);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(VueMask);
Vue.use(VueSweetalert2);
Vue.use(require("vue-chartist"));
Vue.use(vco);
Vue.use(Sparkline);
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    libraries: "places",
  },
  installComponents: true,
});
Vue.component("apexchart", VueApexCharts);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
