import router from "../../router/index";
import { systemAxios } from "../../service/axios.service.js";
import tokenService from "../../service/token.service.js";
const user = JSON.parse(localStorage.getItem("user"));
export const state = user
  ? { status: { loggeduser: true }, user }
  : { status: {}, user: null };

export const actions = {
  // Logs in the user.
  // eslint-disable-next-line no-unused-vars
  async login({ dispatch, commit }, { phone, password }) {
    try {
      console.log("zo day");
      commit("loginRequest", { phone });
      const result = await systemAxios.post("/auth/login", { phone, password });
      const { msg, error, data } = result.data;
      console.log(error);
      console.log(data);
      if (!error) {
        await tokenService.setUser(data);
        commit("loginSuccess", user);
        router.push("/");
      } else {
        commit("loginFailure", error);
        dispatch("notification/error", error, { root: true });
      }
    } catch (err) {
      if (err.response) {
        dispatch("notification/error", err.response.data.msg, { root: true });
      }
    }
  },
  // Logout the user
  async logout({ commit }) {
    try {
      tokenService.removeUser();
      const result = await systemAxios.post("/auth/logout");
      const { msg, error, data } = result.data;
      if (!error) {
        commit("logout");
        router.push("/login").catch(() => {});
      } else {
        dispatch("notification/error", msg, { root: true });
      }
    } catch (err) {
      commit("logout");
      router.push("/login").catch(() => {});
      if (err.response) {
        dispatch("notification/error", err.response.data.msg, { root: true });
      }
    }
  },
  // register the user
  async registeruser({ dispatch, commit }, user) {
    try {
      commit("registerRequest", user);
      const result = await systemAxios.post("/auth/register", user);
      const { msg, error, data } = result.data;
      if (!error) {
        commit("registerSuccess", user);
        dispatch("notification/success", "Registration successful", {
          root: true,
        });
        router.push("/login");
      } else {
        commit("registerFailure", error);
        dispatch("notification/error", error, { root: true });
      }
    } catch (err) {
      if (err.response) {
        dispatch("notification/error", err.response.data.msg, { root: true });
      }
    }
  },
};

export const mutations = {
  loginRequest(state, user) {
    state.status = { loggingIn: true };
    state.user = user;
  },
  loginSuccess(state, user) {
    state.status = { loggeduser: true };
    state.user = user;
  },
  loginFailure(state) {
    state.status = {};
    state.user = null;
  },
  logout(state) {
    state.status = {};
    state.user = null;
  },
  registerRequest(state) {
    state.status = { registering: true };
  },
  registerSuccess(state) {
    state.status = {};
  },
  registerFailure(state) {
    state.status = {};
  },
};
