import { systemAxios } from "../../service/axios.service";
import axios from "axios";

// const BASE_API = "orders/";
const BASE_API = "https://hotro.expressvn.vn/publicApi/";
const DETAIL_API = BASE_API + "trackingGet.php";

export const state = {
  orderTrackingData: null,
};

export const mutations = {
  fetchDataTracking(state, newValue) {
    state.orderTrackingData = newValue;
  },
  resetDataTracking(state, newValue) {
    state.orderTrackingData = null;
  },
  addData(state, newValue) {
    state.userDatas.unshift(newValue);
  },
  updateData: (state, newValue) => {
    const index = state.userDatas.findIndex((item) => item.id === newValue.id);
    if (index !== -1) {
      state.userDatas.splice(index, 1, newValue);
    }
  },
  removeData(state, newValue) {
    const rem = state.userDatas.filter((item) => item.id !== newValue.id);
    state.userDatas = state.userDatas.filter((item) => item.id !== newValue.id);
  },
};

export const actions = {
  async trackingOrder({ commit, dispatch }, queryString) {
    try {
      commit("resetDataTracking");
      console.log(queryString);
      const res = await systemAxios.get(DETAIL_API + queryString);
      const { data, msg, status } = res.data;
      console.log(res);
      if (status) {
        commit("fetchDataTracking", data);
        dispatch("notification/success", "Tìm thấy đơn hàng", { root: true });
      } else {
        dispatch("notification/error", msg, { root: true });
      }
    } catch (err) {
      if (err.response) {
        dispatch("notification/error", err.response.data.msg, { root: true });
      } else {
        dispatch("notification/error", err, { root: true });
      }
    }
  },
};
